import React, {useState} from 'react'
import {graphql} from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import ProjectPreviewGrid from '../components/project-preview-grid'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import {filterOutDocsWithoutSlugs, filterOutListedDocs, getSEOMetadata} from '../lib/helpers'

import Offcuts from '../components/offcuts'

export const query = graphql`
query UnlistedPageQuery {
  page: sanityWorkPage(_id: {regex: "/(drafts.|)workPage/"}) {
    projects {
      id
      previewImage {
        asset {
          _id
          metadata {
            dimensions {
              aspectRatio
            }
            palette {
              dominant {
                background
              }
            }
          }           
        }
        alt
      }
      title
      unlisted
      slug {
        current
      }
      categories {
        title
        _id
      }
    }
    title
    seoFields {
      metaTitle
      metaDescription
      metaImage {
        asset {
          url
        }
      }
    }      
  }
}
`

const WorkPage = props => {
  const {data, errors} = props;
  const projects = data.page.projects
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }
  const [categoryFilterVisible, setCategoryFilterVisible] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(undefined);
  const onCategoryTriggerClick = () => {
    setCategoryFilterVisible(!categoryFilterVisible);
  }
  const onCategoryItemClick = (e, category) => {
    setSelectedCategory(category);
    setCategoryFilterVisible(!categoryFilterVisible);
  }
  const onFilterReset = () => {
    setSelectedCategory(undefined);
    setCategoryFilterVisible(!categoryFilterVisible);
  }
  let projectNodes = data && projects && projects.filter(filterOutDocsWithoutSlugs).filter(filterOutListedDocs);
  let allCategories = [];
  {projectNodes.map(project => {
    allCategories.push(...project.categories)
  })}
  //Remove duplicate categories
  allCategories = allCategories.filter((category, index, self) =>
    index === self.findIndex((t) => (
      t._id === category._id
    ))
  )

  //Filter projects
  const filterProjects = () => {
    const filteredProjects = projectNodes.filter((project) => {
      let found = false;
      for (let i=0;i<project.categories.length;i++) {
        if (project.categories[i]._id === selectedCategory._id) {
          found = true;
          break;
        }
      }
      return found;
    });
    return filteredProjects;
  }
  projectNodes = selectedCategory ? filterProjects() : projectNodes;

  return (
    <Layout>
      <SEO title="Unlisted" noindex />
      <Container>
        <div className="work-header">
          <div className="contents">
            <h4 className="type-body-display">Nothing to see here 👀</h4>
          </div>
        </div>
        {
        }
        {projectNodes && projectNodes.length > 0 && <ProjectPreviewGrid nodes={projectNodes} />}
      </Container>
    </Layout>
  )
}

export default WorkPage
